import {
  Grid,
  GridItem,
  Heading,
  LinkBox,
  LinkOverlay,
  Text,
} from "@chakra-ui/react"
import HeaderBg from "components/layout/HeaderBg"
import Layout from "components/layout/Layout"
import SEO from "components/layout/SEO"
import MaxWidthWrapper from "components/ui/MaxWidthWrapper"
import { PageProps } from "gatsby"
import * as React from "react"

const ProjectItem = ({
  title,
  link,
  children,
}: {
  title: string
  link: string
  children: React.ReactNode
}) => {
  return (
    <LinkBox bgColor="secondary.100" p={8} as={GridItem}>
      <LinkOverlay
        href={link}
        isExternal={true}
        fontWeight={500}
        pb={4}
        display="block"
      >
        {title}
      </LinkOverlay>
      {children}
    </LinkBox>
  )
}

const Projects = (props: PageProps) => {
  return (
    <Layout location={props.location}>
      <SEO title="MN: Projects" />
      <HeaderBg bgColor="white" />
      <MaxWidthWrapper pt={0}>
        <Heading py={12} as="h1" textAlign="center" fontSize="5xl">
          Projects
        </Heading>
        <Grid templateColumns={{ base: "1fr", md: "repeat(2,1fr)" }} gap={6}>
          <ProjectItem
            title="obsidian-user-plugins"
            link="https://github.com/mnowotnik/obsidian-user-plugins"
          >
            <Text>
              a handy plugin for ObsidianMD that I can run user scripts from
              their notes directory. I use it to add convenient additional
              commands to Obsidian.
            </Text>
          </ProjectItem>
          <ProjectItem
            title="noteflow.nvim"
            link="https://github.com/mnowotnik/noteflow.nvim"
          >
            <Text>
              note-taking plugin that aims to reduce friction for taking notes
              in neovim. Tries to supplement other feature-rich note-taking
              editors (like Obsidian) by being a lightweight on-the-run
              alternative.
            </Text>
          </ProjectItem>
          <ProjectItem
            title="fzshell"
            link="https://github.com/mnowotnik/fzshell"
          >
            <Text>
              fzshell - A user-defined fuzzy autocompletion. A shell convenience
              tool that turns a list of sources defined by user into searchable
              lists that can be queried when writing shell commands in terminal.
            </Text>
          </ProjectItem>
        </Grid>

        {/* - promptd - a zsh prompt rendered by daemon go server. Over-engineered solution, 
      but very fast and extensible. Also, you can configure your prompt in go and avoid
      zsh altogether. */}
      </MaxWidthWrapper>
    </Layout>
  )
}

export default Projects
