import React from "react"
import { Box, BoxProps } from "@chakra-ui/react"

export default ({ bgColor, ...props }: BoxProps) => (
  <Box
    height={{ base: 14, md: 20 }}
    w="100%"
    top={0}
    position="sticky"
    bgColor={bgColor}
    zIndex={2}
    mx="auto"
    {...props}
  />
)
