import { Flex, FlexProps } from "@chakra-ui/react"
import { PageProps } from "gatsby"
import React from "react"
import Footer from "./Footer"
import Header from "./Header"
import HeaderBg from "./HeaderBg"
import LayoutBase from "./LayoutBase"

type Props = {
  children: React.ReactNode
  headerBgColor?: string
  location: PageProps["location"]
} & FlexProps

const Layout = ({
  children,
  location,
  headerBgColor = "primary.main",
  ...props
}: Props) => {
  return (
    <LayoutBase {...props}>
      <Header location={location} overflow="hidden" />
      <HeaderBg bgColor={headerBgColor} position="absolute" top="0" />
      <Flex flex="1 1 auto" flexDirection="column">
        {children}
      </Flex>
      <Footer />
    </LayoutBase>
  )
}

export default Layout
